// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getAppSmsList = (params) => {
    return postRequest('/busi/appSms/getByCondition', params)
}
// 添加
export const addAppSms = (params) => {
    return postRequest('/busi/appSms/insert', params)
}
// 编辑
export const editAppSms = (params) => {
    return postRequest('/busi/appSms/update', params)
}
// 删除
export const deleteAppSms = (params) => {
    return postRequest('/busi/appSms/delByIds', params)
}