<template>
	<div class="search">
		<Card>
			<Row v-show="openSearch" @keydown.enter.native="handleSearch">
				<Collapse value="1" accordion style="width:100%">
					<Panel name="1">查询条件
						<p slot="content">
							<Form ref="searchForm" :model="searchForm" :label-width="80" label-position="right">
								<Row :gutter="32">
										<Col span="7">
										<Form-item label="手机号码" prop="telno">
												<Input type="text" v-model="searchForm.telno" placeholder="请输入手机号码" clearable />
										</Form-item>
										</Col>
										<Col span="7">
										<Form-item label="发生日期" prop="fsrq">
												<DatePicker type="date" v-model="searchForm.fsrq" style="display: block" format="yyyy.MM.dd" @on-change="searchForm.fsrq = $event" clearable>
												</DatePicker>
										</Form-item>
										</Col>
										<Col span="7">
										<Form-item label="IMEI码" prop="imei">
												<Input type="text" v-model="searchForm.imei" placeholder="请输入IMEI码" clearable />
										</Form-item>
										</Col>
										<Col span="7">
										<Form-item label="IMEI码2" prop="imei2">
												<Input type="text" v-model="searchForm.imei2" placeholder="请输入IMEI码2" clearable />
										</Form-item>
										</Col>
								</Row>
							</Form>
						</p>
					</Panel>
				</Collapse>
			</Row>
			<Row @keydown.enter.native="handleSearch">
				<Form>
					<Form-item style="margin-top: 10px" class="br">
						<Button @click="handleSearch" type="primary" icon="ios-search">搜索</Button>
						<Button @click="add" type="warning" icon="md-add">添加</Button>
					</Form-item>
				</Form>
			</Row>
			<Row>
				<Table :loading="loading" border :columns="columns" :data="data" ref="table" sortable="custom"
					@on-sort-change="changeSort" @on-selection-change="changeSelect"></Table>
			</Row>
			<Row type="flex" justify="end" class="page">
				<Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize" @on-change="changePage"
					@on-page-size-change="changePageSize" :page-size-opts="[10, 20, 50]" size="small" show-total show-elevator
					show-sizer></Page>
			</Row>
		</Card>
		<appSmsAddEdit :data="formData" :addEditFlag="addEditFlag" v-model="showAddEdit" @on-submit="getDataList" />
	</div>
</template>
<script>
	// 根据你的实际请求api.js位置路径修改
	import {
		getAppSmsList,
		deleteAppSms
	} from "@/api/busi/data-manage/appSms";
	// 根据你的实际添加编辑组件位置路径修改
	import appSmsAddEdit from "./appSmsAddEdit.vue";
	export default {
		name: "appSms",
		components: {
			appSmsAddEdit,
		},
		data() {
			return {
				openSearch: true, // 显示搜索
				formData: {},
				addEditFlag: "1",
				loading: false, // 表单加载状态
				showAddEdit: false,
				searchForm: {
					// 搜索框初始化对象
					pageNumber: 1, // 当前页数
					pageSize: 10, // 页面大小
					sort: "createTime", // 默认排序字段
					order: "desc", // 默认排序方式
					telno: "",
					fsrq: "",
					imei: "",
					imei2: "",
				},
				columns: [
					// 表头
					{
						type: "index",
						width: 60,
						fixed: "left",
						align: "center",
					},
				{
					title: "手机号码",
					key: "telno",
					minWidth: 130,
					sortable: false,
				},
				{
					title: "发生日期",
					key: "fsrq",
					minWidth: 170,
					sortable: false,
				},
				{
					title: "交易单号",
					key: "tradeno",
					minWidth: 120,
					sortable: false,
				},
				{
					title: "IMEI码",
					key: "imei",
					minWidth: 120,
					sortable: false,
				},
				{
					title: "序列号",
					key: "ensn",
					minWidth: 120,
					sortable: false,
				},
				{
					title: "IMEI码2",
					key: "imei2",
					minWidth: 120,
					sortable: false,
				},
				{
					title: "序列号2",
					key: "ensn2",
					minWidth: 120,
					sortable: false,
				},
				{
					title: "购买类型",
					key: "buytype",
					minWidth: 120,
					sortable: false,
				},
					{
						title: "操作",
						key: "action",
						align: "center",
						fixed: "right",
						width: 180,
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										props: {
											type: "success",
											size: "small",
											icon: "ios-create-outline",
										},
										style: {
											marginRight: "5px",
										},
										on: {
											click: () => {
												this.edit(params.row);
											},
										},
									},
									"编辑"
								),
								h(
									"Button", {
										props: {
											type: "warning",
											size: "small",
											icon: "md-trash",
										},
										on: {
											click: () => {
												this.remove(params.row);
											},
										},
									},
									"删除"
								),
							]);
						},
					},
				],
				data: [], // 表单数据
				pageNumber: 1, // 当前页数
				pageSize: 10, // 页面大小
				total: 0, // 表单数据总数
			};
		},
		methods: {
			init() {
				// this.getDataList();
			},
			submited() {
				this.getDataList();
			},
			changePage(v) {
				this.searchForm.pageNumber = v;
				this.getDataList();
				this.clearSelectAll();
			},
			changePageSize(v) {
				this.searchForm.pageSize = v;
				this.getDataList();
			},
			handleSearch() {
				this.searchForm.pageNumber = 1;
				this.searchForm.pageSize = 10;
				this.getDataList();
			},
			handleReset() {
				this.$refs.searchForm.resetFields();
				this.searchForm.pageNumber = 1;
				this.searchForm.pageSize = 10;
				// 重新加载数据
				this.getDataList();
			},
			changeSort(e) {
				this.searchForm.sort = e.key;
				this.searchForm.order = e.order;
				if (e.order === "normal") {
					this.searchForm.order = "";
				}
				this.getDataList();
			},
			getDataList() {
				this.loading = true;
				getAppSmsList(this.searchForm).then((res) => {
					this.loading = false;
					if (res.success) {
						this.data = res.result.records;
						this.total = res.result.total;
					}
				});
			},
			add() {
				this.addEditFlag = "1";
				this.showAddEdit = true;
			},
			edit(v) {
				// 转换null为""
				for (let attr in v) {
					if (v[attr] == null) {
						v[attr] = "";
					}
				}
				let str = JSON.stringify(v);
				let data = JSON.parse(str);
				this.addEditFlag = "2";
				this.formData = data;
				this.showAddEdit = true;
			},
			remove(v) {
				this.$Modal.confirm({
					title: "确认删除",
					// 记得确认修改此处
					content: "您确认要删除?",
					loading: true,
					onOk: () => {
						// 删除
						deleteAppSms({
							ids: v.id,
						}).then((res) => {
							this.$Modal.remove();
							if (res.success) {
								this.$Message.success("操作成功");
								this.getDataList();
							}
						});
					},
				});
			},
		},
		mounted() {
			this.init();
		},
	};
</script>
<style lang="less">
// 建议引入通用样式 具体路径自行修改
//@import "../../../styles/table-common.less";
</style>
